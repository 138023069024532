import ALL_CREDIT_CARD_PAYMENTS from '@/graphql/credit-card-payments/query/getAllCreditCardPayments.gql'

const creditCardPayments = ref([])
const key = ref(0)
const firstTime = ref(true)

export async function creditCardPaymentsAutocomplete(params: any) {
  const apolloQuery = ALL_CREDIT_CARD_PAYMENTS
  const variables = {
    search: params.search ? Number(params.search) : null,
    orderBy: [
      {
        column: 'CREATED_AT',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  if (!variables.search) {
    delete variables.search
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchCreditCardPaymentsCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await creditCardPaymentsAutocomplete({ search: queryString })
    cb(results?.getAllCreditCardPayments?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCreditCardPaymentsCb')
  }
}

export function getSearchCreditCardPayments(entityCreditCardPayment: any, entityCreditCardPayments: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchCreditCardPayments('', entityCreditCardPayment, entityCreditCardPayments)
  }
  return creditCardPayments?.value ?? []
}

export async function searchCreditCardPayments(queryString: any, entityCreditCardPayment: any, entityCreditCardPayments: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await creditCardPaymentsAutocomplete({ search: queryString })
    const remoteCreditCardPayments = results?.getAllCreditCardPayments?.data?.map((i: any) => {
      return {
        label: i.amount,
        value: i.uuid
      }
    }) ?? []
    const creditCardPaymentsToAdd: { label: any; value: any }[] = []
    entityCreditCardPayments?.forEach((j: any) => {
      if (!remoteCreditCardPayments.find((k: any) => k.value === j.uuid)) {
        creditCardPaymentsToAdd.push({
          label: j.amount,
          value: j.uuid
        })
      }
    })
    if (entityCreditCardPayment && !remoteCreditCardPayments.find((k: any) => k.value === entityCreditCardPayment.uuid)) {
      creditCardPaymentsToAdd.push({
        label: entityCreditCardPayment.amount,
        value: entityCreditCardPayment.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    creditCardPayments.value = [...remoteCreditCardPayments, ...creditCardPaymentsToAdd]
    firstTime.value = false
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchCreditCardPayments')
  }
}

export function getCreditCardPaymentKey () {
  return key.value
}

export function initialCreditCardPayments () {
  key.value = 0
  firstTime.value = true
  creditCardPayments.value = []
}